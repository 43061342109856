.dropdownLabel {
    position: relative;
    display: inline-block;
    color: white;
    font-size: medium;
    font-weight: 500; 
    margin-right: 3%;
}

.dropdown {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;
    display: block;
    background-color: black;
    top: 5%;
    &.open>.dropdown-menu {
      display: block;
    }
    .dropdown-toggle {
      background-color:#4A7273;
      display: inline-block;
      right: 4%;
      padding: 6px 12px;
      margin-bottom: 0;
      min-width: 100px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;    
      cursor: pointer;    
      color: #fff;
      //background-color: #337ab7;
      border-color: #2e6da4;
      border: 1px solid transparent;
      border-radius: 4px;
  
      .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-bottom: 4px dashed;
        border-bottom: 4px solid\9;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }
  
    .dropdown-menu {
      position: absolute;
      display: inline-block;
      top: -135px;
      z-index: 1000;
      /*display: none;*/
      min-width: 100px;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
     
      li {
        cursor: pointer;
        :hover {
          background: #4A7273;
          color: white;

        }
        /*& > a {*/
          display: block;
          padding: 5px 20px;
          clear: both;
          font-weight: 400;
          line-height: 1.42857143;
          color: #333;
          white-space: nowrap;
          text-decoration: none;
        //}
      } 
    }  

    li:hover{
        background: #4A7273;
          color: white; 
    }
  }